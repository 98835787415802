
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "RegisterAdmin",
  components: {},
  setup() {
    let data = reactive<any>({
      eidtSign: false,
      init: false,
      data: {},
      form: {
        hosptials: [],
      },
      option: {},
      infoOption: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: { csid: 0 },
        sort: "-lastlogin",
      },
      // baseURL: "http://localhost:5270/api/admin",
      // maps: new Map(),
    });

    const searchChange = (params: any, done: any) => {
      for (let p in params) {
        const field: Column = data.option.column.find(
          (v: Column) => v.prop === p
        );
        if (field.regex) {
          params[p] = { $regex: params[p] };
        }
      }
      data.query.where = params;
      data.page.currentPage = 1;
      data.query.page = 1;
      data.query.limit = data.page.pageSize;
      getList();
      done();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      console.log(data.query);
      Api.Register.GetAdminList(data.query).then((res: any) => {
        console.log(res);
        data.data = {};
        if (res.Code == 0) {
          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const getOption = () => {
      Api.Register.GetAdminOption().then((res: any) => {
        console.log(res);
        data.option = res;
        data.init = true;
      });
    };

    const getInfoOption = () => {
      Api.Register.GetAdminInfoOption().then((res: any) => {
        console.log(res);
        data.infoOption = res;
      });
    };

    const rowSave = (form: any, done: any, loading: any) => {
      Api.Register.AddAdmin(form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const rowUpdate = (form: any, index: any, done: any, loading: any) => {
      Api.Register.EditAdmin(form.id, form).then((res: any) => {
        if (res.Code == 0) {
          data.eidtSign = false;
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const rowHospitalSave = (form: any, done: any, loading: any) => {
      data.eidtSign = true;
      done();
    };

    const rowHospitalDel = (form: any, index: any, done: any) => {
      done(form);
      data.eidtSign = true;
      // done();
    };

    const beforeOpen = (done: any, type: any) => {
      console.log(done, type);
      done();
    };

    const beforeClose = (done: any, type: any) => {
      if (data.eidtSign == true) {
        ElMessageBox.confirm(
          "现在关闭修改并未保存，如需保存请取消后点击保存按钮",
          "警告",
          {
            confirmButtonText: "关闭",
            cancelButtonText: "取消",
            type: "",
          }
        )
          .then(() => {
            data.eidtSign = false;
            done();
          })
          .catch(() => {
            console.log("xx");
          });
      } else {
        done();
      }
    };

    onMounted(() => {
      getOption();
      getInfoOption();
    });

    return {
      ...toRefs(data),
      searchChange,
      changePage,
      rowSave,
      rowUpdate,
      rowHospitalSave,
      rowHospitalDel,
      beforeClose,
      beforeOpen,
    };
  },
});
